import type { User } from 'firebase/auth';
export default defineNuxtRouteMiddleware(async (to) => {
  const user: User | null = await getCurrentUser();

  // redirect the user to the login page
  if (user) {
    return navigateTo({
      path: to.query.redirect as string || '/',
      replace: true,
    });
  }
});